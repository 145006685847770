import React, { lazy, Suspense } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import jwtDecode from "jwt-decode";

import { Provider } from "react-redux";
import store from "../store";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import Spinner from "./components/common/spinner";
import PrivateRoute from "./utils/PrivateRoute";
import setAuthToken from "./utils/setAuthToken";

const Dashboard = lazy(() => import("./components/dashboard"));
const LogInForm = lazy(() => import("./components/loginForm/LogInForm"));
const HomePage = lazy(() => import("./components/homePage/HomePage"));

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwtDecode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "/";
  }
}

export default function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <div className="App" style={{ minHeight: "100%"}}>
          <Suspense fallback={<Spinner />}>
            <Router>
              <Switch>
                <Route path="/login" render={(props) => <LogInForm props={props} />} />
                <PrivateRoute exact path="/stariHomepage" component={() => <HomePage />} />
                <PrivateRoute path="/" component={(props) => <Dashboard props={props} />} />
                <Route exact path="**" render={() => <div>faruk</div>} />
                <Route exact path="404" render={() => <div>faruk</div>} />
              </Switch>
            </Router>
          </Suspense>
        </div>
      </Suspense>
    </Provider>
  );
}
