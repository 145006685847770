import { SET_MAINTENANCE_TRAILER } from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SET_MAINTENANCE_TRAILER:
      return {
        maintenance: action.payload
      };
    default:
      return state;
  }
}
