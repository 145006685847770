import {
  GET_DASHBOARD_TOTAL_TRUCK,
  GET_DASHBOARD_NUMBEROF_TRUCK,
  GET_DASHBOARD_MAINTENANCECOST_TRUCK,
  GET_DASHBOARD_PAIDPERINVOICEITEMS_TRUCK
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_DASHBOARD_TOTAL_TRUCK:
      return {
        ...state,
        totalData: action.payload
      };
    case GET_DASHBOARD_NUMBEROF_TRUCK:
      return {
        ...state,
        numberOfTrucks: action.payload
      };
    case GET_DASHBOARD_MAINTENANCECOST_TRUCK:
      return {
        ...state,
        maintenanceCost: action.payload
      };
    case GET_DASHBOARD_PAIDPERINVOICEITEMS_TRUCK:
      return {
        ...state,
        paidPerInvoiceItems: action.payload
      };
    default:
      return state;
  }
}
