export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_PORTAL = "SET_CURRENT_PORTAL";
// ERRORS
export const GET_ERRORS = "GET_ERRORS";
export const REMOVE_ERRORS = "REMOVE_ERRORS";
// Orders
export const SET_ORDERS_TRUCK = "SET_ORDERS_TRUCK";
export const SET_ORDERS_TRAILER = "SET_ORDERS_TRAILER";
// Dashboard Trailer
export const GET_DASHBOARD_TOTAL_TRAILER = "GET_DASHBOARD_TOTAL_TRAILER";
export const GET_DASHBOARD_NUMBEROF_TRAILER = "GET_DASHBOARD_NUMBEROF_TRAILER";
export const GET_DASHBOARD_MAINTENANCECOST_TRAILER =
  "GET_DASHBOARD_MAINTENANCECOST_TRAILER";
export const GET_DASHBOARD_PAIDPERINVOICEITEMS_TRAILER =
  "GET_DASHBOARD_PAIDPERINVOICEITEMS_TRAILER";
// Dashboard Truck
export const GET_DASHBOARD_TOTAL_TRUCK = "GET_DASHBOARD_TOTAL_TRUCK";
export const GET_DASHBOARD_NUMBEROF_TRUCK = "GET_DASHBOARD_NUMBEROF_TRUCK";
export const GET_DASHBOARD_MAINTENANCECOST_TRUCK =
  "GET_DASHBOARD_MAINTENANCECOST_TRUCK";
export const GET_DASHBOARD_PAIDPERINVOICEITEMS_TRUCK =
  "GET_DASHBOARD_PAIDPERINVOICEITEMS_TRUCK";
// Truck Rental Maintenance
export const SET_MAINTENANCE_TRUCK = "SET_MAINTENANCE_TRUCK";
// Trailer Rental Maintenance
export const SET_MAINTENANCE_TRAILER = "SET_MAINTENANCE_TRAILER";
// Truck RENTAL Equipment
export const SET_EQUIPMENT_TRUCK = "SET_EQUIPMENT_TRUCK";
export const SET_ONEEQUIPMENT_TRUCK = "SET_ONEEQUIPMENT_TRUCK";
// Trailer Leasing Equipment
export const SET_EQUIPMENT_TRAILER = "SET_EQUIPMENT_TRAILER";
export const SET_EQUIPMENT_TRAILER_ONE = "SET_EQUIPMENT_TRAILER_ONE";
// VENDOR PORTAL WORK REQUESTS
export const SET_WORKREQUEST_VENDOR = "SET_WORKREQUEST_VENDOR";
export const UPDATE_WORK_REQUEST = "UPDATE_WORK_REQUEST";

// Documents Truck/Trailer
export const GET_DOCUMENTS_TRAILER = "GET_DOCUMENTS_TRAILER";
export const GET_INSURANCE_DOCUMENTS_TRAILER =
  "GET_INSURANCE_DOCUMENTS_TRAILER";
export const GET_DOCUMENTS_TRUCK = "GET_DOCUMENTS_TRUCK";
