import React from "react";
import { Loader } from "semantic-ui-react";

const LoaderExampleInlineCentered = (props) => (
  <Loader
    active
    inline="centered"
    size="massive"
    style={props.style ? props.style : { marginTop: "35vh"}}
  />
);

export default LoaderExampleInlineCentered;
