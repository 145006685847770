import axios from "axios";
import jwtDecode from "jwt-decode";

import setAuthToken from "../utils/setAuthToken";
import { SET_CURRENT_USER, GET_ERRORS, REMOVE_ERRORS } from "./types";

export const loginUser = userData => async dispatch => {
  try {
    const user = await axios.post("/api/users/login", userData);
    const { token } = user.data;
    localStorage.setItem("jwtToken", token);
    setAuthToken(token);
    const decodedUserData = jwtDecode(token);
    dispatch(setCurrentUser(decodedUserData));
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const setCurrentUser = decodedUserData => {
  return {
    type: SET_CURRENT_USER,
    payload: decodedUserData
  };
};

export const logoutUser = () => dispatch => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  dispatch({
    type: REMOVE_ERRORS
  });
};
