import { SET_EQUIPMENT_TRUCK, SET_ONEEQUIPMENT_TRUCK } from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SET_EQUIPMENT_TRUCK:
      return {
        equipment: action.payload
      };
    case SET_ONEEQUIPMENT_TRUCK:
      return {
        equipment: state.equipment,
        equipmentOne: action.payload
      };
    default:
      return state;
  }
}
