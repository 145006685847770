import {
  SET_EQUIPMENT_TRAILER,
  SET_EQUIPMENT_TRAILER_ONE
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SET_EQUIPMENT_TRAILER:
      return {
        equipment: action.payload
      };
    case SET_EQUIPMENT_TRAILER_ONE:
      return {
        equipment: state.equipment,
        equipmentOne: action.payload
      };
    default:
      return state;
  }
}
