import { SET_WORKREQUEST_VENDOR, UPDATE_WORK_REQUEST } from "../actions/types";

const vendorPortalReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_WORKREQUEST_VENDOR:
      return {
        ...state,
        workRequestsVendor: action.payload
      };

    case UPDATE_WORK_REQUEST:
      const { updatedWorkRequest, type } = action.payload;
      const objIndex = state.workRequestsVendor[type].findIndex(
        obj => updatedWorkRequest._id === obj._id
      );

      if (objIndex > -1) {
        const newState = {
          ...state
        };
        newState.workRequestsVendor[type][objIndex] = updatedWorkRequest;
        return newState;
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default vendorPortalReducer;
