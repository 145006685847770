import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReduces from "./errorReduces";
import routerReducer from "./routerReducer";
import trailerLeasingOrdersReducer from "./trailerLeasingOrdersReducer";
import truckRentalOrdersReducer from "./truckRentalOrdersReducer";
import trailerLeasingDashboardReducer from "./trailerLeasingDashboard";
import truckRentalDashboardReducer from "./truckRentalDashboard";
import truckMaintenanceReducer from "./truckMaintenanceReducer";
import trailerMaintenanceReducer from "./trailerMaintenanceReducer";
import truckEquipmentReducer from "./truckEquipmentReducer";
import trailerEquipmentReducer from "./trailerEquipmentReducer";
import vendorPortalReducer from "./vendorPortalReducer";
import truckRentalDocumentsReducer from "./truckRentalDocumentsReducer";
import trailerLeasingDocumentsReducer from "./trailerLeasingDocumentsReducer";
import trailerLeasingInsuranceDocumentsReducer from "./trailerLeasingInsuranceDocumentsReducer";

export default combineReducers({
  auth: authReducer,
  error: errorReduces,
  router: routerReducer,
  trailerLeasingOrders: trailerLeasingOrdersReducer,
  truckRentalOrders: truckRentalOrdersReducer,
  trailerLeasingDashboard: trailerLeasingDashboardReducer,
  truckRentalDashboard: truckRentalDashboardReducer,
  truckMaintenance: truckMaintenanceReducer,
  trailerMaintenance: trailerMaintenanceReducer,
  truckEquipment: truckEquipmentReducer,
  trailerEquipment: trailerEquipmentReducer,
  vendorPortal: vendorPortalReducer,
  truckDocuments: truckRentalDocumentsReducer,
  trailerDocuments: trailerLeasingDocumentsReducer,
  trailerInsuranceDocuments: trailerLeasingInsuranceDocumentsReducer
});
