import { SET_ORDERS_TRAILER } from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SET_ORDERS_TRAILER:
      return {
        orders: action.payload
      };
    default:
      return state;
  }
}
