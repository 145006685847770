import {
  GET_DASHBOARD_TOTAL_TRAILER,
  GET_DASHBOARD_NUMBEROF_TRAILER,
  GET_DASHBOARD_MAINTENANCECOST_TRAILER,
  GET_DASHBOARD_PAIDPERINVOICEITEMS_TRAILER
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_DASHBOARD_TOTAL_TRAILER:
      return {
        ...state,
        totalData: action.payload
      };
    case GET_DASHBOARD_NUMBEROF_TRAILER:
      return {
        ...state,
        numberOfTrailers: action.payload
      };
    case GET_DASHBOARD_MAINTENANCECOST_TRAILER:
      return {
        ...state,
        maintenanceCost: action.payload
      };
    case GET_DASHBOARD_PAIDPERINVOICEITEMS_TRAILER:
      return {
        ...state,
        paidPerInvoiceItems: action.payload
      };
    default:
      return state;
  }
}
